import axios from "axios";
import { BASE_URL_PAY_API } from "../ConstApis/constApis";

export const getTermsConditions = async (cedula) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL_PAY_API}/merchants?document=${cedula}`
    );
    return data;
  } catch (error) {
    console.error("Error al obtener la cédula", error);
    return {};
  }
};

export const posCreatePaymentApi = async (paymentData) => {
  try {
    const { data } = await axios.post(
      `${BASE_URL_PAY_API}/create-transaction`,
      paymentData
    );
    return data;
  } catch (error) {
    console.error("Error al crear la transacción", error);
    return null;
  }
};

export const getTransactionStatus = async (transactionId) => {
  try {
    const  transactionStatus = await
      axios.get(`${BASE_URL_PAY_API}/status-transaction?transactionId=${transactionId}`);
    return transactionStatus;
  } catch (error) {
    console.error("Error al obtener informe de transacción", error);
    return [];
  }
};

