import Swal from "sweetalert2";


import { theme } from "../../index";
const showAlertError = (title, text) => {
  Swal.fire({
    icon: "error",
    title: title,
    text: text,
    confirmButtonText: "Aceptar",
    didOpen: () => {
      const confirmButton = Swal.getConfirmButton();
      confirmButton.style.color =  theme.palette.primary.main;; // Cambia a tu color preferido
      confirmButton.style.backgroundColor = theme.palette.secondary.main; // Cambia el color de fondo
    }

  });
};

export const showAlertInfo = (title, text) => {
  Swal.fire({
    icon: "info",
    title: title,
    text: text,
    confirmButtonText: "Aceptar",
    didOpen: () => {
      const confirmButton = Swal.getConfirmButton();
      confirmButton.style.color =  theme.palette.primary.main;; // Cambia a tu color preferido
      confirmButton.style.backgroundColor = theme.palette.secondary.main; // Cambia el color de fondo
    }
  });
};

export default showAlertError;
