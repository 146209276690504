export default function generatePdf(base, name){
    const pdf = {
      file: `data:application/pdf;base64,${base}`,
      file_name:  name,
    };

    const anchorElement = document.createElement("a");
    anchorElement.href = pdf.file;
    anchorElement.download = pdf.file_name + ".pdf";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  }
