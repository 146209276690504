import * as React from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FormId from "./FormId";
import Header from "./Header/Header";
import { theme } from "../index";
import Footer from "./Footer/Footer";

export default function Home() {
  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: { xs: "80vh", sm: "90vh" },
        }}
      >
        <Box
          width={{ xs: "80%", sm: "75%", md: "50%", lg: "40%" }}
          maxWidth="400px"
          my={4}
          p={3}
          sx={{
            border: `1.5px solid ${theme.palette.primary.main}`,
            borderRadius: "10px",
            boxShadow: `0px 3px 10px #0047BA`,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: `0px 3px 10px #0047BA`,
              transform: "scale(1.02)",
            },
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold" }}
            color={theme.palette.primary.main}
          >
            Consultar tu factura
          </Typography>

          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
            noValidate
          >
            <FormId />
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  );
}
