import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Typography, Button, CircularProgress } from "@mui/material";
import Navegar from "../Assets/images/logotipo-navegar-300x300.png";
import BotonBancolombia from "../Assets/images/bancolombia-boton-icon.png";
import Pdf from "../Assets/images/pdf_filetype_icon_227891.webp";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header/Header";
import { theme } from "../index";
import { getInvoicesPdf } from "../Data/Invoices/invoices";
import showAlertError from "./Utils/Messages";
import generatePdf from "./Utils/Documents";
import { useAccess } from "../Router/AccessContext";

export default function Invoices() {
  const navigate = useNavigate();
  const location = useLocation();
  const { customerInvoices } = location.state || {};
  const invoicesArray = customerInvoices?.invoices || [];
  const [isLoading, setIsLoading] = useState(false);
  const [loadingInvoiceId, setLoadingInvoiceId] = useState(null);
  const { setHasAccess } = useAccess();

  const handleClick = (invoice) => {
    navigateToPayment(invoice);
  };

  const navigateToPayment = (invoice) => {
    setHasAccess(true);
    navigate("/pago", { state: { invoice } });
  };

  const handlePdfDownload = async (invoiceId) => {
    try {
      setLoadingInvoiceId(invoiceId);
      setIsLoading(true);
      const data = await getInvoicesPdf(invoiceId);
      if (data && data.base64) {
        setIsLoading(false);
        setLoadingInvoiceId(null);
        generatePdf(data.base64, "Factura_" + invoiceId);
      } else {
        setIsLoading(false);
        setLoadingInvoiceId(null);
        showAlertError(
          "Error",
          "El Pdf no se pudo descargar, intentalo más tarde"
        );
      }
    } catch (error) {
      setIsLoading(false);
      showAlertError(
        "Error",
        "El Pdf no se pudo descargar, intentalo más tarde"
      );
    }
  };

  return (
    <>
      <Header />
      <Box
        sx={{
          p: 1,
          backgroundColor: "#ffffff",
          width: "100%",
          maxWidth: "900px",
          margin: "100px auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          "@media (max-width: 1200px)": {
            maxWidth: "90%",
          },
          "@media (max-width: 768px)": {
            maxWidth: "90%",
          },
          "@media (max-width: 600px)": {
            maxWidth: "80%",
          },
        }}
      >
        {invoicesArray.map((invoice, index) => (
          <Box
            key={index}
            sx={{
              borderRadius: "10px",
              p: 2,
              backgroundColor: "#ffffff",
              mb: 2,
              width: "100%",
              boxShadow: `1px 2px 4px #007CBA, -4px -4px 8px rgba(255, 255, 255, 0.7)`,
              transition: "transform 0.2s",
              "@media (max-width: 1200px)": {
                maxWidth: "100%",
              },
              "@media (max-width: 768px)": {
                maxWidth: "100%",
              },
              "@media (max-width: 600px)": {
                maxWidth: "100%",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                alignItems: "center",
                mb: 2,
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "flex-start",
                },
              }}
            >
              <Typography variant="body1">
                Concepto:
                <strong>{invoice.concept}</strong>
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1,
                  "@media (max-width: 600px)": {
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  },
                }}
              >
                <Typography variant="body1">
                  Referencia:
                  <strong>{invoice.name}</strong>
                </Typography>

                <div>
                  {isLoading && loadingInvoiceId === invoice.id ? (
                    <CircularProgress id={invoice.id} />
                  ) : (
                    <img
                      id={invoice.id}
                      src={Pdf}
                      alt="pdf"
                      style={{
                        width: "40px",
                        height: "40px",
                        cursor: "pointer",
                        "@media (max-width: 600px)": {
                          width: "30px",
                          height: "30px",
                        },
                      }}
                      onClick={() => handlePdfDownload(invoice.id)}
                    />
                  )}
                </div>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                "@media (max-width: 600px)": {
                  flexDirection: "column",
                  alignItems: "center",
                },
              }}
            >
              <img
                src={Navegar}
                alt="Navegar"
                style={{
                  width: "80px",
                  height: "80px",
                  "@media (max-width: 600px)": {
                    marginBottom: "10px",
                  },
                }}
              />

              <Typography
                variant="h5"
                sx={{
                  color: theme.palette.primary.main,
                  fontFamily: "Sans-serif",
                  fontWeight: 600,
                  letterSpacing: "2px",
                }}
              >
                {invoice.balance === 0
                  ? "Pagada"
                  : `$${invoice.balance?.toLocaleString()}`}
              </Typography>

              <Typography variant="body1">
                Fecha límite: {invoice.datePaid}
              </Typography>

              {!invoice.paid && (
                <Button
                  style={{
                    border: "none",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => handleClick(invoice)}
                >
                  <img
                    src={BotonBancolombia}
                    alt="Botón Bancolombia"
                    style={{
                      width: "auto",
                      height: "40px",
                      "@media (max-width: 600px)": {
                        height: "30px",
                      },
                    }}
                  />
                </Button>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
}
