import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "./Router/AppRouter";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#0047BA",
    },
    secondary: {
      main: "#F7E506",
    },
    third: {
      main: "#7A7A7A",
    },
    four: {
      main: "#007CBA",
    },
    basic: {
      main: "#ffffff",
    },
  },
});
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AppRouter />
    </ThemeProvider>
  </React.StrictMode>
);
