import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAccess } from './AccessContext';

const ProtectedRoute = ({ children }) => {
    const { hasAccess } = useAccess();

    return hasAccess ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;