import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import Invoices from "../Home/Invoices";
import FormsCustomers from "../Home/FormsCustomers";
import ThankYou from "../Home/ThankYou";
import { AccessProvider } from "./AccessContext";
import ProtectedRoute from "./ProtectedRouter";
const AppRouter = () => {
  return (
    <AccessProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="facturas"
            element={
              <ProtectedRoute>
                <Invoices />
              </ProtectedRoute>
            }
          />
          <Route
            path="pago"
            element={
              <ProtectedRoute>
                <FormsCustomers />
              </ProtectedRoute>
            }
          />
          <Route
            path="thank-you"
            element={   
                <ThankYou /> }
          />
           <Route path="*" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </AccessProvider>
  );
};

export default AppRouter;
