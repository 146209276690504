import React from "react";
import { Box, Typography, Link, Tooltip } from "@mui/material";
import { theme } from "../../index";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.basic.main,
        padding: "15px 0",
        position: "fixed",
        bottom: 0,
        left: "0",
        width: "100%",
        textAlign: "center",
        boxSizing: "border-box",
        margin: "0",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          mr: 2,
          fontWeight: 400,
          letterSpacing: "2px",
        }}
      >
        MERECES DESCONECTARTE DE TODO Y LLENARTE DE BUENAS EXPERIENCIAS
      </Typography>
      <br />
      <Link
        href="https://marinanavegar.co/"
        target="_blank"
        rel="noopener noreferrer"
        sx={{
          textDecoration: "none",
          color: "inherit",
          fontWeight: "bold",
        }}
      >
        <Typography>Visita nuestro sitio web</Typography>
      </Link>
      <br />

      <Typography
        sx={{
          fontSize: "12px",
        }}
      >
        Aplicación desarrollada por <span></span>
        <a
          href="https://mi-portafolio-lizethmontoya.vercel.app/"
          style={{ textDecoration: "none", color: "white" }}
        >
          Lizeth Montoya
        </a>
        <span style={{ margin: "0 8px" }}>-</span>
        <Tooltip title="duvercarmona@gmail.com" arrow>
          <a  href="mailto:duvercarmona@gmail.com" style={{ textDecoration: "none", color: "white" }}>
            Duver Carmona
          </a>
        </Tooltip>
      </Typography>
      <Typography
        sx={{
          fontSize: "12px",
        }}
      >
       Todos los derechos reservados. 2024
      </Typography>
    </Box>
  );
};

export default Footer;
