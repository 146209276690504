import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormHelperText,
  Container,
  Button,
  FormGroup,
  Checkbox,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { getTermsConditions, posCreatePaymentApi } from "../Data/Pay/pay";
import Header from "./Header/Header";
import { theme } from "../index";
import TextField from "@mui/material/TextField";
import showAlertError from "./Utils/Messages";
import { useNavigate } from "react-router-dom";

const FormsCustomers = () => {
  const location = useLocation();
  const { invoice } = location.state || {};
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    nameComplete: "",
    email: "",
    phone: "",
    acept: "",
    documentType: "",
    document: ""
  });

  const [errors, setErrors] = useState({
    nameComplete: "",
    email: "",
    phone: "",
    acept: "",
    documentType: "",
    document: ""
  });

  const [payData, setPayData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchPayData = async () => {
      if (invoice && invoice.customerIdentification) {
        try {
          setIsLoading(true);
          const data = await getTermsConditions(invoice.customerIdentification);
          setPayData(data);
        } catch (error) {
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchPayData();
  }, [invoice]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const fieldValidation = () => {
    let validation = true;
    const newErrors = {};

    if (!formData.nameComplete) {
      newErrors.nameComplete = "El nombre es obligatorio";
      validation = false;
    }

    if (!formData.email) {
      newErrors.email = "El correo es obligatorio";
      validation = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "El correo no es válido";
      validation = false;
    }

    if (!formData.phone) {
      newErrors.phone = "El celular es obligatorio";
      validation = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = "El celular debe tener 10 dígitos";
      validation = false;
    }
    if (!formData.acept) {
      newErrors.acept = "Debe aceptar los términos y condiciones";
      validation = false;
    }
    if (!formData.documentType) {
      newErrors.documentType = "Seleccionar";
      validation = false;
    }
    if (!formData.document) {
      newErrors.document = "El documento es obligatorio";
      validation = false;
    }else if(!/^[0-9]*$/.test(formData.document)){
      newErrors.document = "El documento debe ser númerico";
      validation = false;
    }else if(formData.document.length<=5){
    newErrors.document = "El campo de documento no cumple con el tamaño.";
    validation = false;
  }

    setErrors(newErrors);
    return validation;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (fieldValidation()) {
      if (!payData) {
        showAlertError(
          "Error entidad bancaria",
          "No es posible iniciar el pago, intente más tarde"
        );
      }
      const posCreatePayment = {
        trace: "PAGOS-FRONT",
        payload: {
          document: invoice.customerIdentification,
          acceptanceToken: payData?.acceptanceToken,
          amount: invoice?.balance,
          customerMail: formData.email,
          paymentDescription: invoice?.concept,
          invoiceName: invoice?.name,
          invoiceId: invoice?.id,
          customerPayment: {
            phoneNumber: formData.phone,
            fullName: formData.nameComplete,
            document: formData.document,
            documentType: formData.documentType,
          },
        },
      };

      try {
        const response = await posCreatePaymentApi(posCreatePayment);
        if (response.urlRedirect === "PENIDING") {
          showAlertError(
            "Error entidad bancaria",
            "No es posible iniciar el pago, intente más tarde"
          );
        } else {
          window.open(response.urlRedirect, "_self", "noopener noreferrer");
        }
      } catch (error) {
        showAlertError(
          "Error entidad bancaria",
          "No es posible iniciar el pago, intente más tarde"
        );
      }
    }
  };

  const handleCancelClick = () => {
    navigate("/");
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Header />
          <Box
            sx={{
              border: `1.5px solid ${theme.palette.four.main}`,
              borderRadius: "10px",
              p: 2,
              backgroundColor: "#ffffff",
              maxWidth: "800px",
              margin: "100px auto",
              display: "flex",
              flexDirection: "column",
              color: "black",
              boxShadow: `1px 2px 4px #007CBA, 
          -4px -4px 8px rgba(255, 255, 255, 0.7)`,
              transition: "transform 0.2s",
              "@media (max-width: 600px)": {
                maxWidth: "100%",
              },
            }}
            component="form"
            onSubmit={handleSubmit}
          >
            <div>
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center",
                  fontFamily: "Sans-serif",
                  fontWeight: 400,
                  color: theme.palette.primary.main,
                }}
              >
                <strong>DETALLE DEL PAGO</strong>
              </Typography>
              <br />
              <br />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  alignItems: "center",
                  mb: 2,
                }}
              >
                <Typography variant="body1">
                  Concepto: <strong>{invoice?.concept}</strong>
                </Typography>
                <Typography variant="body1">
                  Referencia: <strong>{invoice?.name}</strong>
                </Typography>
              </Box>

              <Box sx={{ textAlign: "center" }}>
                <Typography
                  variant="h4"
                  sx={{ color: "primary.main", fontWeight: "bold" }}
                >
                  ${invoice?.balance?.toLocaleString()}
                </Typography>
              </Box>
            </div>
            <br />
            <div>
              <Typography sx={{ fontStyle: "italic" }}>
                *Únicamente puedes pagar por este medio si tienes cuenta en
                Bancolombia o Ahorro a la mano Bancolombia. Esperamos habilitar
                otras pasarelas próximamente.
              </Typography>
              <br />
              <Typography sx={{ fontWeight: "bold" }}>
                Ingresa los datos del pagador:
              </Typography>
              <br />

              <FormGroup>
                
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 2,
                  }}
                >
                  <FormControl fullWidth margin="normal" sx={{ flex: 0.4 }}>
                  <label>Nombre completo</label>
                  <TextField
                    placeholder="Nombre completo"
                    name="nameComplete"
                    value={formData.nameComplete}
                    onChange={handleChange}
                    fullWidth
                  />
                  <FormHelperText error>{errors.nameComplete}</FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" sx={{ flex: 0.2 }}>
                <label>
                  Tipo documento
                </label>
                <Select
                name="documentType"
                  value={formData.documentType}
                  label="Tipo documento"
                  onChange={handleChange}
                >
                  <MenuItem value={"CC"}>CC</MenuItem>
                  <MenuItem value={"NIT"}>NIT</MenuItem>
                  <MenuItem value={"CE"}>CE</MenuItem>
                </Select>
                <FormHelperText error>{errors.documentType}</FormHelperText>
                </FormControl>
                <FormControl fullWidth margin="normal" sx={{ flex: 0.4 }}>
                    <label>Documento</label>
                    <TextField
                      placeholder="Documento"
                      name="document"
                      value={formData.document}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      sx={{
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                      }}
                    />
                    <FormHelperText error>{errors.document}</FormHelperText>
                  </FormControl>
                  </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: 2,
                  }}
                >
                  <FormControl fullWidth margin="normal" sx={{ flex: 1 }}>
                    <label>Correo</label>
                    <TextField
                      placeholder="Correo"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      fullWidth
                    />
                    <FormHelperText error>{errors.email}</FormHelperText>
                  </FormControl>

                  <FormControl fullWidth margin="normal" sx={{ flex: 1 }}>
                    <label>Celular</label>
                    <TextField
                      placeholder="Celular"
                      name="phone"
                      value={formData.phone}
                      onChange={handleChange}
                      fullWidth
                      type="number"
                      sx={{
                        "& input[type=number]": {
                          MozAppearance: "textfield",
                        },
                        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                          {
                            WebkitAppearance: "none",
                            margin: 0,
                          },
                      }}
                    />
                    <FormHelperText error>{errors.phone}</FormHelperText>
                  </FormControl>
                </Box>
                <FormControl>
                  <Typography>
                    <Checkbox
                      {..."text"}
                      name="acept"
                      onChange={handleChange}
                    />
                    Acepto haber leído los términos y condiciones para hacer
                    esta compra. Revísalos&nbsp;
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={
                        payData?.permalink
                          ? payData?.permalink
                          : "https://wompi.com/assets/downloadble/reglamento-Usuarios-Colombia.pdf"
                      }
                    >
                      aquí
                    </a>
                  </Typography>
                  <FormHelperText error>{errors.acept}</FormHelperText>
                </FormControl>
              </FormGroup>

              <Container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.2rem",
                  marginTop: "20px",
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleCancelClick}
                  sx={{
                    backgroundColor: "white",
                    color: theme.palette.primary.main,
                    fontSize: "1rem",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: theme.palette.four.main,
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{
                    backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    fontSize: "1rem",
                    fontWeight: "bold",
                    "&:hover": {
                      backgroundColor: theme.palette.four.main,
                      color: theme.palette.secondary.main,
                    },
                  }}
                >
                  Continuar pago
                </Button>
              </Container>
            </div>
          </Box>
        </>
      )}
    </div>
  );
};

export default FormsCustomers;
