import axios from "axios";
import { BASE_URL_INVOICES_API } from "../ConstApis/constApis";

export const getInvoices = async (cedula) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL_INVOICES_API}/getInvoice?document=${cedula}`
    );
    return data;
  } catch (error) {
    console.error("Error al obtener la cédula", error);
    return [];
  }
};

export const getInvoicesPdf = async (idCodePfd) => {
  try {
    const { data } = await axios.get(
      `${BASE_URL_INVOICES_API}/getStringInvoice?codeInvoice=${idCodePfd}`
    );
    return data;
  } catch (error) {
    console.error("Error al obtener el PDF:", error);
    return {};
  }
};
