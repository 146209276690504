import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";
import { theme } from "../../index";
import AdbIcon from "../../Assets/images/logotipo-navegar-300x300.png";

const pages = ["Inicio", "Facturas", "Pago"];

function Header() {
  const location = useLocation();

  const currentPath = location.pathname;

  const shouldShow = (page) => {
    switch (page) {
      case "Inicio":
        return currentPath === "/";
      case "Facturas":
        return currentPath === "/Facturas";
      case "Pago":
        return currentPath === "/Pago";
      default:
        return false;
    }
  };

  const getCurrentPage = () => {
    switch (currentPath) {
      case "/":
        return "Inicio";
      case "/Facturas":
        return "Facturas";
      case "/Pago":
        return "Pago";
      default:
        return "";
    }
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: "white",
        color: theme.palette.primary.main,
      }}
    >
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link to="/" style={{ display: "flex", marginRight: "8px" }}>
            <img
              src={AdbIcon}
              alt=""
              style={{
                width: "90px",
                height: "90px",
              }}
            />
          </Link>
          <Typography
            variant="h6"
            noWrap
            component={Link}
            to="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".rem",
              color: theme.palette.primary.main,
              textDecoration: "none",
            }}
          >
            NAVEGAR HOSTERIA & MARINA
          </Typography>

          {/* Muestra solo el nombre de la página activa en pantallas pequeñas */}
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <Typography
              variant="h6"
              noWrap
              sx={{
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".rem",
                color: theme.palette.primary.main,
                textDecoration: "none",
              }}
            >
              {getCurrentPage()}
            </Typography>
          </Box>

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) =>
              shouldShow(page) ? (
                <Button
                  key={page}
                  component={Link}
                  to={
                    page.toLowerCase() === "inicio"
                      ? "/"
                      : `/${page.toLowerCase()}`
                  }
                  sx={{
                    my: 2,
                    color: theme.palette.four.main,
                    display: "block",
                    textDecoration: "none",
                    pointerEvents: "auto",
                  }}
                >
                  {page}
                </Button>
              ) : null
            )}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default Header;
