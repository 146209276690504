import React, { useState } from "react";
import {
  FormControl,
  FormHelperText,
  Container,
  Button,
  Input,
  FormGroup,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { getInvoices } from "../Data/Invoices/invoices";
import { theme } from "../index";
import showAlertError from "./Utils/Messages";
import { showAlertInfo } from "./Utils/Messages";
import { useAccess } from "../Router/AccessContext";

const FormId = () => {
  const [cedula, setCedula] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { setHasAccess } = useAccess();

  const validateCedula = (value) => {
    const validation = /^[0-9]*$/;
    if (!validation.test(value)) {
      setError("El documento solo puede contener números.");
      return false;
    }
    return true;
  };

  const handleChange = (event) => {
    const { value } = event.target;
    if (validateCedula(value)) {
      setCedula(value);
    }
  };

  const handleClick = async () => {
    if (cedula.length <= 5) {
      setError("El campo de documento no cumple con el tamaño.");
      return;
    }
    setIsLoading(true);

    try {
      const customerInvoices = await getInvoices(cedula);
      if (customerInvoices.invoices.length === 0) {
        showAlertInfo("Sin facturas", "No se encontraron facturas");
      } else {
        navigateToInvoices(customerInvoices);
      }
    } catch (error) {
      setIsLoading(false);
      showAlertError("Eror", "Ocurrio un error listando las facturas");
    } finally {
      setIsLoading(false);
    }
  };

  const navigateToInvoices = (customerInvoices) => {
    setHasAccess(true);
    navigate("/facturas", { state: { customerInvoices } });
  };

  return (
    <div>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <FormGroup
            display="flex"
            sx={{
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FormControl margin="normal" error={!!error}>
              <label>Documento del titular</label>
              <Input
                placeholder="Documento"
                name="cedula"
                value={cedula}
                onChange={handleChange}
                type="number"
                sx={{
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                  "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
                    {
                      WebkitAppearance: "none",
                      margin: 0,
                    },
                }}
              />
              <FormHelperText>{error}</FormHelperText>
            </FormControl>
          </FormGroup>

          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0.2rem",
            }}
          >
            <Button
              variant="contained"
              onClick={handleClick}
              sx={{
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.primary.main,
                fontSize: "1rem",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: theme.palette.four.main,
                  color: theme.palette.secondary.main,
                },
              }}
            >
              Consultar
            </Button>
          </Container>
        </>
      )}
    </div>
  );
};

export default FormId;
