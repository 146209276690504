import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Typography, Button } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { getTransactionStatus } from "../Data/Pay/pay";
import { theme } from "../index";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";

export default function ThankYou() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const [transactionStatus, setTransactionStatus] = useState({});

  useEffect(() => {
    const fetchTransactionStatus = async () => {
      try {
        const response = await getTransactionStatus(id);
        setTransactionStatus(response.data);
      } catch (err) {
      } finally {
      }
    };
    if (id) {
      fetchTransactionStatus();
    }
  }, [id]);

  const handleClick = () => {
    navigate("/");
  };

  const card = (
    <React.Fragment>
      <CardContent sx={{ textAlign: "center" }}>
        <Typography
          variant="h5"
          sx={{ fontWeight: "bold" }}
          color="text.primary"
          gutterBottom
        >
          INFORME DE TRANSACCIÓN
        </Typography>
        <Typography variant="body2">
          Estado: {transactionStatus.status}
        </Typography>
        <Typography variant="body2">
          Factura: {transactionStatus.invoiceName}
        </Typography>
        <Typography variant="body2">
          Concepto: {transactionStatus.concept}
        </Typography>
        <Typography variant="body2">
          Valor: {transactionStatus.amount}
        </Typography>
        <br />
        <br />
        <Typography variant="body2" color="text.secondary">
          Puede consultar nuevamente en unos minutos el estado de su factura.
        </Typography>
      </CardContent>
      <CardActions sx={{ justifyContent: "center" }}>
        <Button
          onClick={handleClick}
          variant="contained"
          sx={{
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
            fontSize: "1rem",
            fontWeight: "bold",
            "&:hover": {
              backgroundColor: theme.palette.four.main,
              color: theme.palette.secondary.main,
            },
          }}
        >
          Aceptar
        </Button>
      </CardActions>
    </React.Fragment>
  );

  return (
    <>
      <Header />
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 2,
        }}
        minHeight={{ xs: "78vh", sm: "87vh", md: "87vh", lg: "87vh" }}
      >
        <Box
          variant="outlined"
          sx={{
            maxWidth: 400,
            width: "100%",
            padding: 2,
            border: `1.5px solid ${theme.palette.primary.main}`,
            borderRadius: "10px",
            boxShadow: `0px 3px 10px #0047BA`,
            transition: "all 0.3s ease-in-out",
            "&:hover": {
              boxShadow: `0px 3px 10px #0047BA`,
              transform: "scale(1.02)",
            },
          }}
        >
          {card}
        </Box>
      </Box>
      <Footer />
    </>
  );
}
